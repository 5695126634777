import React from "react";

export default class Animal extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isLoaded: false,
      animal: ""
    };
    this.scoreLoaded = this.scoreLoaded.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.animal !== prevProps.animal) {
      this.scoreLoaded(this.props.animal);
    }
  }

  scoreLoaded(animal) {
    this.setState({
      isLoaded: true,
      animal: animal
    });
  }

  renderList(items) {
    return (
      <ul>
        {Object.entries(items).map(([key, value], index) => (
          <li key={index}>
            <strong>{key}:</strong> {value}
          </li>
        ))}
      </ul>
    );
  }

  render() {
    return (
      <div class="animal">
        <div class="container">
          <div className="row text-center"> 
            <h3 id="animal">Your Personality</h3>
            <div id="name"><h4>{this.props.animal["name"]}</h4></div>
            <div id="description">{this.props.animal["description"]}</div>
          </div>
          <div className="row text-center">
            <div id="traits-container" className="d-flex text-center">
              <div id="traits" className="text-center">
                <h4>Your Powers</h4>
                {this.renderList(this.props.animal["strengths"])}
              </div>
              <div id="traits" className="text-center">
                <h4>Precautions to Watch Out For</h4>
                {this.renderList(this.props.animal["weaknesses"])}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
