import axios from "axios";
import React, { Component } from "react";
import Animal from "./animal"
import Stars from "./stars";
import Clash from "./clash";
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";


const materialTheme = createMuiTheme({
  typography: {
    htmlFontSize: 8,
    fontFamily: 'Poppins, sans-serif',
  },
  palette: {
    primary: {
      main: '#900603',
    }
  }
});

// const mockSajuData = {
//   animal: {
//     name: "Tiger",
//     description: "Your confidence, charm and courage will transform you into a stand-out leader who can handle any hiccups that comes along.",
//     strengths: {
//       "Boldness": "Active, energetic, and don't fear authority.",
//       "Determination": "Persistent and don't give up easily.",
//       "Confidence": "Know what they want and are determined to get there.",
//       "Independence": "Headstrong and often call the shots.",
//       "Competitiveness": "Competitive and unpredictable.",
//       "Charm": "Charming and well-liked by others.",
//       "Authoritativeness": "Authoritative and never go back on what they have said.",
//       "Leadership": "Make great leaders because of their strength, determination, and confidence."
//     },
//     weaknesses: {
//       "Self-Promotion": "Struggle with projecting themselves before others.",
//       "Empathy": "Capable of deep thinking and great sympathy.",
//       "Impatience": "Short-tempered and lack interest in long-term efforts.",
//       "Conflict with Authority": "Tend to clash with seniors and people in authority.",
//       "Hasty Decisions": "Hasty thinking and decisions may lead to poor results.",
//       "Overconfidence": "Overconfident and traitorous, which can hinder communication and cooperation."
//     }
//   }
// };

class TryItOut extends Component {
  constructor() {
    super();
    this.handleDateChange = this.handleDateChange.bind(this)
    this.onChangeValue = this.onChangeValue.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      selectedDate: new Date('2000-01-01T00:00'),
      gender: '',
      defaultSaju: {},
      saju: {},
      showSaju: false
    }
  }

  handleDateChange(date) {
    this.setState({
      selectedDate: date
    })
  }

  onChangeValue(event) {
    this.setState({
      gender: event.target.value
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    let params = {
      params: {
        birthdate: this.state.selectedDate.toLocaleDateString(),
        gender: this.state.gender
      }
    }
    // this.setState({
    //   showSaju: true,
    //   saju: mockSajuData
    // })
    
    axios
      .get("/saju", params)
      .then(({ data }) => {
        this.setState({
          saju: data,
          showSaju: true
        });
      })
      .catch(error => {
        console.error(error)
      })
  }

  render() {
    return (
      <ThemeProvider theme={materialTheme}>
        <div id="tryItOut" className="text-center">
          <div className="section-title">
            <h2>Find your true talents</h2>
            <h3>Free. No test required. Only your birthday.</h3>
            <p>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker label="Input birthdate (required)" format="MM/dd/yyyy" minDate={new Date('1920-01-01')} maxDate={new Date('2030-12-31')} value={this.state.selectedDate} onChange={this.handleDateChange} />
                {/* <KeyboardTimePicker label="time optional" value={this.state.selectedDate} onChange={this.handleDateChange} /> */}
              </MuiPickersUtilsProvider>
            </p>
            {/* <div className="gender">And gender:</div> */}
            {/* <div className="radio-btn" onChange={this.onChangeValue}>
              <label id="female"><input type="radio" value="Female" name="gender" /> Female </label>
              <label id="male"><input className="radio-btn-desc" type="radio" value="Male" name="gender" /> Male </label>
            </div> */}
          </div>
          <button onClick={this.handleSubmit} type="submit" className="btn btn-custom btn-lg">
            Submit
          </button>
          {/* <h3 id="sajuTitle">Your Month's Fortune</h3> */}
          {/* <Stars saju={this.state.saju} /> */}
          {/* <Clash clash={this.state.saju["clash"]} /> */}
        </div>
        {this.state.showSaju && <Animal animal={this.state.saju["animal"]} />}
      </ThemeProvider>
    );
  }
}

export default TryItOut;
